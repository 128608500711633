.adminpanel-page-wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.log-out-button, .home-button {
    width: 150px;
    height: 50px;
    margin: 15px;
    font-family: Poppins;
    background-color: rgb(61, 131, 211);
    border-radius: 12px;
    border: none;
    color: white;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
}

.adminpanel-wrapper {
    width: 100vw;
    height: 100vh;
}

.adminpanel-upload-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

@media only screen and (max-width: 1010px) {
    .adminpanel-upload-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
}