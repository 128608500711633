@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.div-slider {    
    width: 100vw;
    height: 94vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-image 1.2s ease-in-out;
}

.loaderHolder {
    height: 94vh;
    display: flex;
    justify-content: space-around;
}

.loader {
    margin: auto;
}

.fade-in {
    animation: fadeIn ease 3s;
    -webkit-animation: fadeIn ease 3s;
    -moz-animation: fadeIn ease 3s;
    -o-animation: fadeIn ease 3s;
    -ms-animation: fadeIn ease 3s;
}


@keyframes fadeIn{
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
.homepage {
    width: 100vw;
    height: 94vh;
}
.about-us {
    width: 100vw;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.divonama {
    overflow: auto;
}

@media only screen and (max-width: 810px) {
    .about-us {
        width: 90vw;
        height: 94vh;
        display: flex;
        align-items: center;
    
    }
    
    .spanonama {
        margin-left: 3vw;
        margin-top: 25vh;

    }

    .divonama {
        overflow: auto;
    }
}
.pc-buttons {
    width: 400px;
    height: 6vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    
}

.dropdown-hide {
    margin-top: -200px;
    transition-property: all;
    transition-duration: .5s;  
}

.dropdown-show {
    margin-top: -2px;
    transition-property: all;
    transition-duration: .5s;  
}

@media only screen and (max-width: 600px) {

    .pc-buttons {
        display: none;
    }

    a {
        font-family: Poppins;
    }

    .mob-buttons {
        width: 100vw;
        height: 150px;
        position: relative;
        z-index: 9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-color: rgb(50, 50, 50);
    }

}
.container {
    width: 100vw;
    height: 6vh;
}

.header {
    font-family: Poppins;
    width: 100vw;
    height: 6vh;
    background-color: rgb(50, 50, 50);
    display: flex;
    justify-content: space-between;
}

.logo-holder {
    width: 150px;
    height: 6vh;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 12;
    margin-left: 15px;
}

.mobile-view {
    display: none;
}

.signin-holder {
    width: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-right: 15px;
}

button {    
    color: white;
    font-size: 15px; 
    background-color: transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;        
}

button:active {
    outline: none;
    border: none;
}

button:focus {outline:0;}

a {
    text-decoration: none;
    color: white;
    font-size: 15px;
}


@media only screen and (max-width: 600px) {

    .header {
        font-family: Poppins;
        width: 100vw;
        height: 6vh;
        background-color: rgb(50, 50, 50);
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 10;
    }

    .buttons {
        width: 150px;
        height: 180px;
        display: flex;
        flex-direction: column;
    }

    .button-holder {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
    }
    
    .mobile-view {
        height: 6vh;
        display: block;
    }
}

.sign-in-page {
    /* background-color: blue; */
    width: 100vw;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signin-form {
    width: 350px;
}

.signin-wrapper {
    position: relative;
}

.signin-label {
    font-family: Poppins;
    position: absolute;
    font-size: 1.25rem;
    left: 15px;
    pointer-events: none;
    color: rgb(85, 85, 85);
    transition: all .22s;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: block;
}

.signin-input{
    font-family: Poppins;
    width: 100%;
    padding: 10px;
    margin: 15px 0;
    border: none;
    color: #4e4e4e;
    font-size: 1.125rem;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid rgb(85, 85, 85);
}

.signin-input:focus + label,
.signin-input:hover + label,
.signin-input:valid + label
{
    top: 0;
    font-size: 1.125rem;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    left: 0;
    opacity: 1;
}

.signin-input:-webkit-autofill,
.signin-input:-webkit-autofill:hover, 
.signin-input:-webkit-autofill:focus, 
.signin-input:-webkit-autofill:active
{
    box-shadow: 0 0 0 30px white inset !important;
}

.submit-button {
    width: 150px;
    height: 50px;
    background-color: rgb(61, 131, 211);
    border-radius: 12px;
    padding: 12px;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor:pointer;
    pointer-events: all;
}

.signin-button-wrapper {
    display: flex;
    justify-content: center;
}

.submit-button-disable {
    display: none;
}

.submit-spiner-enable {
    display: block;
    width: 50px;
    height: 50px;
}

.submit-spiner-disable {
    display: none;
}
.adminpanel-page-wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.log-out-button, .home-button {
    width: 150px;
    height: 50px;
    margin: 15px;
    font-family: Poppins;
    background-color: rgb(61, 131, 211);
    border-radius: 12px;
    border: none;
    color: white;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
}

.adminpanel-wrapper {
    width: 100vw;
    height: 100vh;
}

.adminpanel-upload-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

@media only screen and (max-width: 1010px) {
    .adminpanel-upload-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
}
.custom-file-upload {
    font-family: Poppins;
    width: 250px;
    display: inline-block;
    cursor: pointer;
}

.black-line {
    width: 100vw;
    height: 1px;
    background-color: black;
    margin: 10px 0;
}

.image-gallery-upload-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.span-ce {
    font-family: Poppins;
    font-weight: 600;
    font-size: 1rem;
    left: 15px;
    pointer-events: none;
    color: rgb(85, 85, 85);
    display: block;
}

.upload-button-wrapper {
    width: 250px;
    height: 50px;
    display: flex;
    justify-content: center;
}

.submit-upload-button {    
    width: 100px;
    height: 50px;
    background-color: rgb(61, 131, 211);
    border-radius: 12px;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    cursor:pointer;
    pointer-events: all;
}

.upload-button-disable {
    display: none;
}

.upload-spiner-enable {
    display: block;
    width: 50px;
    height: 50px;
}

.upload-spiner-disable {
    display: none;
}

.joinus-select {
    width: 250px;
    height: 25px;
    margin: 5px 0;
}

.select-input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.joinus-label {
    font-family: Poppins;
    font-weight: 600;
    font-size: 1rem;
    left: 15px;
    pointer-events: none;
    color: rgb(85, 85, 85);
    display: block;
}




.del-imgs-from-server-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.delete-button {
    margin-left: 50px;
}

.view-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

label {
    font-family: Poppins;
}
.add-gallery-span {
    font-family: Poppins;
    font-weight: 600;
    font-size: 15px;
    left: 12px;
    pointer-events: none;
    color: rgb(85, 85, 85);
    display: block;
    margin: 0 0 10px 0

}

.add-gallery-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.joinus-label {
    font-family: Poppins;
    font-weight: 600;
    font-size: 1rem;
    left: 15px;
    pointer-events: none;
    color: rgb(85, 85, 85);
    display: block;
}

.joinus-input{
    font-family: Poppins;
    width: 250px;
    margin: 0 0 5px 0;
    border: none;
    color: #4e4e4e;
    font-size: 1rem;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid rgb(85, 85, 85);
}
.list-image {
    width: 150px;
    height: 90px;
    background-size: cover;
    background-repeat: no-repeat;
}

.load-images-wrapper {    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
}

.trash-img {
    width: 25px;
    cursor: pointer;
}
.tumbHolder {
    display: flex;
    justify-content: center;
    width: 256px;
    height: 160px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
    margin: 20px;
    cursor: pointer;
}

.tumbName {
    font-family: Poppins;
    text-align: center;
    width: 100vw;
    height: 20px;
    border-radius: 15px 15px 0px 0px;
    background-color: white;
}

@media only screen and (max-width: 810px){
    
    .tumbHolder {
        display: flex;
        justify-content: center;
        width: 150px;
        height: 100px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 15px;
        margin: 10px;
        cursor: pointer;
    }

}
.gallery-page-wrapper {
    width: 900px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

@media only screen and (max-width: 810px){

    .gallery-page-wrapper {
        width: 340px;
        margin: auto;
        align-items: center;
        flex-wrap: wrap;
    }  

}
.imagegallery-page-wrapper {
    width: 800px;
    display: flex;
    align-items: center;
    margin: auto;
    cursor: pointer;
}

.slikaImg {
    width: 256px;
    height: 160px;
    background-color: rgba(145, 145, 145, 0.5);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
    margin: 5px;
}

.gallery-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;}


@media only screen and (max-width: 810px){
    
    .imagegallery-page-wrapper {
        width: 100vw;
        display: flex;
        margin: auto;
        cursor: pointer;
    }

    .gallery-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .slikaImg {
        width: 150px;
        height: 100px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 15px;
        margin: 5px;
    }

}
.fullscreenimg-wrapper {
    width: 100vw;
    height: 94vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.fullscreenimg {
    width: calc(90vh * 1.8);
    height: 90vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-around;
}

.previous-button, .exit-button, .next-button {
    align-self: flex-end;
    width: 20vw;
    font-family: Poppins;
    border: none;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    background-color: rgb(50, 50, 50);
}


@media only screen and (max-width: 810px) {

    .fullscreenimg {
        width: 95vw;
        height: calc(90vw/1.1);
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: space-around;
    }

    .previous-button, .exit-button, .next-button {
        align-self: flex-end;
        width: 33vw;
        font-family: Poppins;
        border: none;
        color: rgb(0, 0, 0);
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        background-color: white;
    }
}

.containerKontakt {
    width: 100vw;
    height: 94vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.iframe-wrapper {
    width: 550px;
    height: 550px;
}

@media only screen and (max-width: 810px) {
    
    .containerKontakt {
        width: 100vw;
        height: 94vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .iframe-wrapper {
        width: 90vw;
        height: calc(90vw/0.9);
    }
}
body {
  box-sizing: border-box;
  font-family: "Open Sans Condensed";
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App {
  width: 100vw;
};


