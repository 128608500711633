.gallery-page-wrapper {
    width: 900px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

@media only screen and (max-width: 810px){

    .gallery-page-wrapper {
        width: 340px;
        margin: auto;
        align-items: center;
        flex-wrap: wrap;
    }  

}