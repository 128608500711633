.fullscreenimg-wrapper {
    width: 100vw;
    height: 94vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.fullscreenimg {
    width: calc(90vh * 1.8);
    height: 90vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-around;
}

.previous-button, .exit-button, .next-button {
    align-self: flex-end;
    width: 20vw;
    font-family: Poppins;
    border: none;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    background-color: rgb(50, 50, 50);
}


@media only screen and (max-width: 810px) {

    .fullscreenimg {
        width: 95vw;
        height: calc(90vw/1.1);
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: space-around;
    }

    .previous-button, .exit-button, .next-button {
        align-self: flex-end;
        width: 33vw;
        font-family: Poppins;
        border: none;
        color: rgb(0, 0, 0);
        text-decoration: none;
        font-size: 16px;
        cursor: pointer;
        background-color: white;
    }
}
