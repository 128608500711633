.pc-buttons {
    width: 400px;
    height: 6vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    
}

.dropdown-hide {
    margin-top: -200px;
    transition-property: all;
    transition-duration: .5s;  
}

.dropdown-show {
    margin-top: -2px;
    transition-property: all;
    transition-duration: .5s;  
}

@media only screen and (max-width: 600px) {

    .pc-buttons {
        display: none;
    }

    a {
        font-family: Poppins;
    }

    .mob-buttons {
        width: 100vw;
        height: 150px;
        position: relative;
        z-index: 9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-color: rgb(50, 50, 50);
    }

}