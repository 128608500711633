.about-us {
    width: 100vw;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.divonama {
    overflow: auto;
}

@media only screen and (max-width: 810px) {
    .about-us {
        width: 90vw;
        height: 94vh;
        display: flex;
        align-items: center;
    
    }
    
    .spanonama {
        margin-left: 3vw;
        margin-top: 25vh;

    }

    .divonama {
        overflow: auto;
    }
}