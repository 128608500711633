.imagegallery-page-wrapper {
    width: 800px;
    display: flex;
    align-items: center;
    margin: auto;
    cursor: pointer;
}

.slikaImg {
    width: 256px;
    height: 160px;
    background-color: rgba(145, 145, 145, 0.5);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
    margin: 5px;
}

.gallery-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;}


@media only screen and (max-width: 810px){
    
    .imagegallery-page-wrapper {
        width: 100vw;
        display: flex;
        margin: auto;
        cursor: pointer;
    }

    .gallery-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .slikaImg {
        width: 150px;
        height: 100px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 15px;
        margin: 5px;
    }

}