.div-slider {    
    width: 100vw;
    height: 94vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-image 1.2s ease-in-out;
}

.loaderHolder {
    height: 94vh;
    display: flex;
    justify-content: space-around;
}

.loader {
    margin: auto;
}

.fade-in {
    animation: fadeIn ease 3s;
    -webkit-animation: fadeIn ease 3s;
    -moz-animation: fadeIn ease 3s;
    -o-animation: fadeIn ease 3s;
    -ms-animation: fadeIn ease 3s;
}


@keyframes fadeIn{
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}