.del-imgs-from-server-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.delete-button {
    margin-left: 50px;
}

.view-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

label {
    font-family: Poppins;
}