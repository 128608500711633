@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

body {
  box-sizing: border-box;
  font-family: "Open Sans Condensed";
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App {
  width: 100vw;
};

